import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Product } from 'api/generated/backend/models';
import { CancellationRequest } from 'api/generated/backend/models/cancellation-request';
import { CancellationManagementService, ProductService } from 'api/generated/backend/services';
import { FormsDataService } from 'src/app/shared/services/frontend/forms-data.service';

@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss'],
})
export class CancelSubscriptionComponent implements OnInit {
  titleForm = 'Kündingung deines Abonements';
  public cancellationForm!: FormGroup;
  readonly EMAIL_PATTERN = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$';
  products: string[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private cancellationManagementService: CancellationManagementService,
    private formsDataService: FormsDataService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.productService.getProducts().subscribe(
      (next) => {
        this.products.push(...next.products.map((p) => p.name));

      },
      (error) => {
        console.log(error.error);
      }
    );
    this.createCancelForm();
    this.addConditionallyOtherValidators();
  }

  onSubmit() {
    this.formsDataService.cancellationForm = this.cancellationForm;
    const cancelFormValue = this.cancellationForm.value;
    const cancellationRequest: CancellationRequest = {
      abo: cancelFormValue.abo,
      email: cancelFormValue.email,
      firstName: cancelFormValue.firstName,
      lastName: cancelFormValue.lastName,
      reason: cancelFormValue.reason,
      type:
        cancelFormValue.type == 'Ordentliche Kündigung'
          ? 'REGULAR'
          : 'IRREGULAR',
    };

    this.cancellationManagementService
      .writeSubscriptionItemCancellation({ body: cancellationRequest })
      .subscribe(
        (next) => {},
        (error) => {
          console.log(error.error);
        }
      );
    if (this.cancellationForm.valid) {
      this.router.navigateByUrl('confirm-email');
    }
  }

  createCancelForm() {
    this.cancellationForm = this.fb.group({
      type: ['Ordentliche Kündigung', Validators.required],
      reason: [' '],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null,[Validators.required, Validators.pattern(this.EMAIL_PATTERN)]],
      abo: [null, Validators.required],
    });
  }

  addConditionallyOtherValidators() {
    this.cancellationForm.get('remark')?.valueChanges.subscribe((value) => {
      if (this.isIrregularCancel()) {
        this.cancellationForm.get('remark')?.addValidators(Validators.required);
      } else {
        this.cancellationForm.clearValidators();
      }
    });
  }

  isIrregularCancel(): boolean {
    return this.cancellationForm.value.type === 'Außerordentliche Kündigung';
  }

  isTypeValid() {
    return this.cancellationForm.controls['type'].errors === null;
  }

  isReasonValid() {
    return this.cancellationForm.controls['reason'].errors === null;
  }

  isEmailValid() {
    return this.cancellationForm.controls['email'].errors === null;
  }

  isFirstNameValid() {
    return this.cancellationForm.controls['firstName'].errors === null;
  }

  isLastNameValid() {
    return this.cancellationForm.controls['lastName'].errors === null;
  }

  isAboValid() {
    return this.cancellationForm.controls['abo'].errors === null;
  }
}
